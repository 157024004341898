.container{

   
   overflow-y: hidden;
   white-space: nowrap;
   position: relative;
   display: block;
   -webkit-overflow-scrolling: touch;
   padding: 16px;

}

.container img{
    
    margin: 2px;
    max-height: 50px;
    cursor: pointer;
    display:inline-block;
    display:inline;/* For IE7*/
    zoom:1;/* For IE7*/
    vertical-align:top;
}
.container::-webkit-scrollbar-button{
   display: none;
}

