@font-face {
    font-family: 'Gotham';
    src: local('Gotham Light'), local('Gotham-Light'),
        url(./fonts/Gotham-Light.woff2) format('woff2'),
        url(./fonts/Gotham-Light.woff) format('woff'),
        url(./fonts/Gotham-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Gotham';
    src: local('Gotham Bold'), local('Gotham-Bold'),
        url(./fonts/Gotham-Bold.woff2) format('woff2'),
        url(./fonts/Gotham-Bold.woff) format('woff'),
        url(./fonts/Gotham-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Gotham';
    src: local('Gotham Book'), local('Gotham-Book'),
    url(./fonts/Gotham-Book.woff2) format('woff2'),
    url(./fonts/Gotham-Book.woff) format('woff'),
    url(./fonts/Gotham-Book.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Gotham'
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: rgb(236,241,241);
    overflow-y: overlay;
    overflow-x: hidden;

  }
  
  html{
    overflow-y :scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height:8px;
    border-radius: 14px;
    cursor: pointer;
  }
  
  
  